<template>
     <section class="demo flex">

          <div class="upload-content">
               <div class="item">
                    <h1>上传头像</h1>
                    <upload-avatar accept=".png" @on-success="avatar" :uuid="uuid" />
               </div>

               <div class="item">
                    <h1>上传文件</h1>
                    <upload-picture-card
                            :uuid-list="uuids"
                            :limit="4"
                            accept=".jpg,.jpeg,.png,.gif,.pdf"
                            @on-success="picture"
                            @on-remove="pictureRemove"
                    />
               </div>
          </div>

          <div class="img-content">
               <h1>上传头像回显</h1>
               <div>
                    <img :src="src()" alt="">
               </div>

               <h1>上传图片回显</h1>
               <div class="flex align-center justify-between">
                    <img v-for="item in fileList(uuids)" :src="item.url" :key="item.name" alt="">
               </div>
          </div>
     </section>
</template>

<script>
    import uploadAvatar from "@/components/Upload/upload-avatar";
    import uploadPictureCard from "@/components/Upload/upload-picture-card";
    import { createFullImageUrl, createFormatUploadImages } from "@/components/Upload";

    export default {
        name: "index",
        data() {
            return {
                uuid: "37e017bfe83b43b59456368a5e4ba3de",
                uuids: ['37e017bfe83b43b59456368a5e4ba3de', '9af7ad7781c04382baebd690a382f93b']
            };
        },
        components: { uploadAvatar, uploadPictureCard },
        methods: {
            avatar({ fileName, info, file }) {
                console.log("avatar-fileName", fileName);
                console.log("avatar-info", info);
                console.log("avatar-file", file);
            },
            picture({ fileName, info, file, fileList }) {
                console.log("picture-fileName", fileName);
                console.log("picture-info", info);
                console.log("picture-file", file);
                console.log("picture-fileList", fileList);
            },
            pictureRemove({ file, fileList }) {
                console.log("picture-remove-file", file);
                console.log("picture-remove-fileList", fileList);
            },
            src() {
                return createFullImageUrl(this.uuid);
            },
            fileList(uuids) {
                return createFormatUploadImages(uuids);
            }
        }
    };
</script>

<style lang="scss" scoped>
     .demo {
          width: 100vw;
          height: 100vh;
          padding: 50px;


          .upload-content {
               width: 60%;
          }

          .img-content {
               width: 40%;
               height: 800px;
               padding: 20px;
               background-color: rgba(140, 147, 157, 0.27);

               img {
                    width: 100px;
               }
          }
     }
</style>
